<template>

  <div style="width: 100%">
    <div class="leisure_button_group_sub">
      <!--           <router-link tag="button" class="btn02 bw3"
                              :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_HILOW_5S}"
                              :to="{path:'/front/tgame/hilow5s', query: {t: new Date().getTime()}}">
                     하이로우 5초
                 </router-link>-->


      <router-link tag="button" class="btn02"
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_HILOW}"
                   :to="{path:'/front/tgame/hilow10s', query: {t: new Date().getTime()}}">
        하이로우
      </router-link>
      <router-link tag="button" class="btn02"
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_RUOLLET}"
                   :to="{path:'/front/tgame/rullet', query: {t: new Date().getTime()}}">
        룰렛
      </router-link>
<!--      <router-link tag="button" class="btn02 bw3"-->
<!--                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_FW}"-->
<!--                   :to="{path:'/front/tgame/hilowfw', query: {t: new Date().getTime()}}">-->
<!--        포츈힐-->
<!--      </router-link>-->
      <router-link tag="button" class="btn02"
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_BACCARAT}"
                   :to="{path:'/front/tgame/baccarat', query: {t: new Date().getTime()}}">
        바카라
      </router-link>

    </div>

<!--    <div class="tgamebetcaount">-->
<!--      당일 토큰게임 베팅 {{ betcount }} 회-->
<!--    </div>-->


    <!--           <router-link tag="button" class="bw4"-->
    <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_SNAIL3}"-->
    <!--                        :to="{path:'/tgame365/tgame365snail3', query: {t: new Date().getTime()}}">-->
    <!--               달팽이 3분-->
    <!--           </router-link>-->
    <!--           <router-link tag="button" class="bw4"-->
    <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_SNAIL4}"-->
    <!--                        :to="{path:'/tgame365/tgame365snail4', query: {t: new Date().getTime()}}">-->
    <!--               달팽이 4줄-->
    <!--           </router-link>-->

    <!--           <router-link tag="button" class="bw3"-->
    <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_COMP_TGAMEPOWERBALL}"-->
    <!--                        :to="{path:'/leisure/tgamepowerball', query: {t: new Date().getTime()}}">-->
    <!--               토큰파워볼-->
    <!--           </router-link>-->
  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";
import {tgamebetcount} from "@/network/userRequest";

export default {
  name: "Tgames365GameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
      betcount: 0,
    }
  },
  created() {
    // tgamebetcount().then(res => {
    //   this.betcount = res.data.data;
    // })
  }
}
</script>

<style scoped>

</style>