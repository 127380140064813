<template>
  <div class="content">
    <sub-title>토큰게임</sub-title>
    <leisure-game-comp-links></leisure-game-comp-links>
    <tgames365-game-links></tgames365-game-links>
    <router-view></router-view>
    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>
</template>

<script>

  import leisureConst from "@/common/leisureConst";

  import {postionMixin, } from "@/common/mixin";
  import SubTitle from "@/views/afront/zero/SubTitle.vue";
  import LeisureGameCompLinks from "@/views/afront/leisure/LeisureCompLinks.vue";
  import Tgames365GameLinks from "@/views/afront/tgame/Tgames365GameLinks.vue";
  import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";


  export default {
    name: "Tgame365",
    mixins:[postionMixin],
    components: {
      MyUncheckedMessageComp,
      Tgames365GameLinks,
      LeisureGameCompLinks,
      SubTitle
    },
    data(){
      return {
        leisureConst:leisureConst,
        position:"토큰게임",
      }
    },
    created() {
      this.$store.state.currentLeisureGameComp =leisureConst.LEISURE_COMP_TOKENGAME
      // if(this.$store.state.userInfo.userGroup.bettgameable == leisureConst.NOT){
      //   this.$swal({
      //     title: '토큰게임 이용이 제한되였습니다',
      //     type: 'warning',
      //     showCancelButton: false,
      //     showConfirmButton: true
      //   })
      //   this.$router.push('/main')
      // }
    }

  }
</script>

<style scoped>
@import url("../../../assets/css/front/afrontminigame.css");
</style>