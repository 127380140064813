<template>
  <div class="banner">
    <a href="https://t.me/wine365" target="_blank">
      <img alt="" src="../../../assets/images/banner/tel.gif">
    </a>
    <a href="http://와인주소.com" target="_blank">
      <img alt="" src="../../../assets/images/banner/www.gif">
    </a>
    <a href="https://www.rotowire.com/" target="_blank">
      <img alt="" src="../../../assets/images/banner/lineup.gif">
    </a>

    <img alt="" src="../../../assets/images/banner/19not.gif">

  </div>

</template>
<script>
export default {
  name: "RightBannerComp"
}
</script>
<style scoped>
.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

}

.banner a {
}

.banner img {
  width: 100%;

}
</style>