<template>

  <div class="content">
    <div class="main_img">
      <swiper :options="swiperOption">
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main02.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main03.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main04.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!--2줄 이미지-->
    <div class="sub_pc_img">
      <div class="item">
        <router-link to="/front/sports" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn01.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/inplay'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn02.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/minigame/ssd1'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn03.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/casino'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn04.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/slot'}"   tag="span">
          <img src="../../assets/images/main/ptn/bn05.jpg" alt="">
        </router-link>
      </div>
    </div>

    <!--모바일-->
    <div class="sub_mobile_menus">
      <div class="smm_left">

        <div class="ml" @click="activePanel(4)" :class="{'active':activenum === 4}">
          <img src="../../assets/images/main/mobile/mobile04.png" alt="">
          <span>스포츠</span>
        </div>
        <div class="ml" @click="activePanel(3)" :class="{'active':activenum === 3}">
          <img src="../../assets/images/main/mobile/mobile03.png" alt="">
          <span>미니게임</span>
        </div>
        <div class="ml" @click="activePanel(1)" :class="{'active':activenum === 1}">
          <img src="../../assets/images/main/mobile/mobile01.png" alt="">
          <span>카지노</span>
        </div>
        <div class="ml" @click="activePanel(2)" :class="{'active':activenum === 2}">
          <img src="../../assets/images/main/mobile/mobile02.png" alt="">
          <span>슬롯</span>
        </div>
      </div>
      <div class="smm_right">
        <div class="menupanel" v-if="activenum === 4">
          <router-link class="mr" tag="div" :to="{path: '/front/sports', query: {t: new Date()}}">
            <img src="../../assets/images/main/mobile/sports/1.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/sports_special', query: {t: new Date()}}">
            <img src="../../assets/images/main/mobile/sports/2.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/inplay', query: {t: new Date()}}">
            <img src="../../assets/images/main/mobile/sports/3.jpg" alt="">
          </router-link>
        </div>
        <!--카지노-->
        <div class="menupanel minigame" v-if="activenum === 1">
          <div class="mr" @click="openGame('PowerBall0000001','evolution',3)">
            <img src="../../assets/images/casino/c/00.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('evolution_baccarat_sicbo','evolution',3)">
            <img src="../../assets/images/casino/c/01.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('dgcasino','DreamGame',3)">
            <img src="../../assets/images/casino/c/02.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('B001','Asia Gaming',3)">
            <img src="../../assets/images/casino/c/03.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('401','PragmaticPlay Live',3)">
            <img src="../../assets/images/casino/c/04.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('bota','bota',3)">
            <img src="../../assets/images/casino/c/05.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('wmcasino','WM Live',3)">
            <img src="../../assets/images/casino/c/06.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('MX-LIVE-001','sexybcrt',3)">
            <img src="../../assets/images/casino/c/07.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('SMG_titaniumLiveGames_MP_Baccarat','MicroGaming Plus',3)">
            <img src="../../assets/images/casino/c/08.jpg" alt="">
          </div>
          <div class="mr" @click="openGame('sw_ro_spbac','Skywind Live',3)">
            <img src="../../assets/images/casino/c/09.jpg" alt="">
          </div>
        </div>
        <!--슬롯-->
        <div class="menupanel minigame" v-if="activenum === 2">
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'EVOPLAY'}}">
            <img src="../../assets/images/main/mobile/slot/1.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'PragmaticPlay'}}">
            <img src="../../assets/images/main/mobile/slot/2.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'Habanero'}}">
            <img src="../../assets/images/main/mobile/slot/3.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'CQ9'}}">
            <img src="../../assets/images/main/mobile/slot/4.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'PlayStar'}}">
            <img src="../../assets/images/main/mobile/slot/5.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'GameArt'}}">
            <img src="../../assets/images/main/mobile/slot/6.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'Relax Gaming'}}">
            <img src="../../assets/images/main/mobile/slot/7.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'Booongo'}}">
            <img src="../../assets/images/main/mobile/slot/8.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'PlaySon'}}">
            <img src="../../assets/images/main/mobile/slot/9.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/slotlist', query: {slotcode: 'Nolimit City'}}">
            <img src="../../assets/images/main/mobile/slot/10.jpg" alt="">
          </router-link>
        </div>
        <!--미니게임-->
        <div class="menupanel minigame" v-if="activenum === 3" style="display: flex;align-items: center;justify-content: space-evenly;flex-wrap: wrap">

          <router-link class="mr" tag="div" :to="{path: '/front/tgame/hilow10s', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/18.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/tgame/rullet', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/19.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/tgame/hilow10s', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/20.jpg" alt="">
          </router-link>

          <router-link class="mr" tag="div" :to="{path: '/front/minigame/sky_oddeven', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/2.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/sky_speed_bakara', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/3.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/sky_dice', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/4.jpg" alt="">
          </router-link>

          <router-link class="mr" tag="div" :to="{path: '/front/minigame/bet365_superleague', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/5.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/bet365_water', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/6.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/bet365_goldenhill', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/7.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/bet365_horserace2', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/8.jpg" alt="">
          </router-link>

          <router-link class="mr" tag="div" :to="{path: '/front/minigame/ssd1', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/9.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/ssd2', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/10.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/ssd3', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/11.jpg" alt="">
          </router-link>

          <router-link class="mr" tag="div" :to="{path: '/front/minigame/jwcasinoangel', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/12.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/jwcasinoost', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/13.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/jwcasinosupermario', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/14.jpg" alt="">
          </router-link>

          <router-link class="mr" tag="div" :to="{path: '/front/minigame/speedgamepdali', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/15.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/speedgamelm', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/16.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/speedgamesplit', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/17.jpg" alt="">
          </router-link>


          <router-link class="mr" tag="div" :to="{path: '/front/minigame/candy_dragontiger', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/1.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" :to="{path: '/front/minigame/royalsutda', query: {t: new Date().getTime()}}">
            <img src="../../assets/images/main/mobile/mini/21.jpg" alt="">
          </router-link>
          <router-link class="mr" tag="div" to="">
            <img src="../../assets/images/main/mobile/mini/commingsoon.jpg" alt="">
          </router-link>

        </div>

      </div>
    </div>


    <!--메인 공지,이벤트-->
    <div class="noticeandresult">
      <div class="nt">
        <div class="nt_title">
          공지사항
          <router-link tag="span" to="/front/notice"
                       style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
          </router-link>
        </div>
        <div class="cont_warp">
          <router-link tag="div" :to="{path: '/front/notice', query: {id:item.id,t: new Date().getTime()}}"
                       v-for="item in noticeList"
                       class="nt_cont">
            <!--                            <span> <img src="../../assets/images/icon/common/notice.png"></span> {{item.title}}-->
            <span style="color: #b58c32">(공지)</span> {{ item.title }}
          </router-link>
        </div>

      </div>
      <div class="nt">
        <div class="nt_title">
          이벤트
          <router-link tag="span" to="/front/event"
                       style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
          </router-link>
        </div>
        <div class="cont_warp">
          <router-link tag="div" :to="{path: '/front/event', query: {id:item.id,t: new Date().getTime()}}"
                       v-for="item in eventList"
                       class="nt_cont">
            <!--                            <span> <img src="../../assets/images/icon/common/event.png"></span> {{item.title}}-->
            <span style="color: #b58c32">(이벤트)</span> {{ item.title }}
          </router-link>
        </div>

      </div>
    </div>

    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>

</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
import PopupComp from "@/views/afront/notice/PopupComp.vue";
import {getEvent, getNoticeList} from "@/network/userRequest";
import {getRecentlyEndGames, getRecentlyGames} from "@/network/sportsRequest";
import sportsConst from "@/common/sportsConst";
import inplayConst from "@/common/inplayConst";
import WarningForSample from "@/views/afront/zero/WarningForSample.vue";
import {casinoMixin, checkLoginOnCreateMinxin, maintaingMixin, postionMixin} from "@/common/mixin";

export default {
  name: "Main",
  components: {WarningForSample, PopupComp, MyUncheckedMessageComp, Footer, Right, Header, Left},
  mixins: [maintaingMixin,postionMixin, casinoMixin],
  data() {
    return {
      sportsConst,
      inplayConst,
      noticeList: [],
      eventList: [],
      soccer: [],
      base: [],
      basket: [],
      hokey: [],
      esports: [],
      vol: [],
      swiperOption: {
        pagination: {el: '.swiper-pagination'},
        autoplay: {},
        disableOnInteraction: false,
        delay: 2000,
      },
      position: "메인",
      activenum: 4
    }
  },
  methods: {
    activePanel(num) {
      this.activenum = num;
    }
  },
  created() {
    // getRecentlyGames().then(res => {
    //   if (res.data.success) {
    //     let recentlryGames = res.data.data
    //     this.soccer = recentlryGames.soccer
    //     this.basket = recentlryGames.bascket
    //     this.base = recentlryGames.base
    //     this.vol = recentlryGames.vol
    //     this.hokey = recentlryGames.hockey
    //     this.esports = recentlryGames.esports
    //   }
    // })
    // getRecentlyEndGames().then(res => {
    //   //this.endgameList = res.data.data
    // })
    getNoticeList(100).then(res => {
      this.noticeList = res.data.data
    })
    getEvent(1, 100).then(res => {
      this.eventList = res.data.data
    })
    this.$store.state.userinsertagentcode = '';
    this.$store.state.ischeckedagentcode = false;
  }
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontmain.css");

.active {
  background: linear-gradient(to bottom, #845d18, #be984f, #845d18);
}
</style>