<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_STAR_SD_1}"
                 :to="{path:'/front/minigame/ssd1', query: {t: new Date().getTime()}}">별다리 1분
    </router-link>
    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_STAR_SD_2}"
                 :to="{path:'/front/minigame/ssd2', query: {t: new Date().getTime()}}">별다리 2분
    </router-link>
    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_STAR_SD_3}"
                 :to="{path:'/front/minigame/ssd3', query: {t: new Date().getTime()}}">별다리 3분
    </router-link>
  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompStarSadaliGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>