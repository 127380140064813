<template>
  <!--베팅카트-->
  <div class="bet_cart">
<!--            <clock></clock>-->
    <div class="cart_head">
      <div class="title">BETTING CART</div>
    </div>
    <div class="cart">
      <div class="sitem" v-for="(item,index) in $store.state.sportsCartItems" :key="item.id">
        <div class="item_name">
          <div class="name">

            <span v-if="item.isbonus === sportsConst.YES">보너스 배당</span>
            <span v-else> {{item.homeTeamName}}</span>
          </div>
          <div class="selectway">
            <div v-if="item.kind === sportsConst.GAME_KIND_1X2">
              <span v-if="item.selectWay === sportsConst.WAY_HOME">승</span>
              <span v-if="item.selectWay === sportsConst.WAY_DRAW">무</span>

              <span v-if="item.selectWay === sportsConst.WAY_AWAY">패</span>
            </div>
            <div v-if="item.kind === sportsConst.GAME_KIND_HANDICAP" class="hd">
              <span v-if="item.selectWay === sportsConst.WAY_HOME">승 ({{item.handicapVal}})</span>
              <span v-if="item.selectWay === sportsConst.WAY_AWAY">
                                    패 ({{item.handicapVal}})
                            </span>
            </div>
            <div v-if="item.kind === sportsConst.GAME_KIND_OVERUNDER" class="ou">
              <span v-if="item.selectWay === sportsConst.WAY_HOME">오버 ({{item.overunderVal}})</span>
              <span v-if="item.selectWay === sportsConst.WAY_AWAY">언더 ({{item.overunderVal}})</span>
            </div>
          </div>
          <div class="selectodds">
            {{item.selectOdd}}
          </div>
          <div class="del" @click="del(index,item)">
            <i class="fa fa-close"></i>
          </div>
        </div>

      </div>
    </div>
    <div v-if="$store.state.sportsCartItems.length === 0" class="cart_selected_items">
      <div class="item">경기를 선택하세요</div>
    </div>
    <div class="info">
      <div class="item">
        <div class="it">
          <span class="t1">최대베팅</span>
          <span class="t2" v-if="$store.state.displayPostion !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.betMaxLimitCash|comma}} 원</span>
          <span class="t2" v-else>{{this.rankConfig.specBetMaxLimitCash|comma}} 원</span>
        </div>
        <div class="it">
          <span class="t1">최대적중</span>
          <span class="t2" v-if="$store.state.displayPostion !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.winMaxLimitCash|comma}} 원</span>
          <span class="t2" v-else>{{this.rankConfig.specWinMaxLimitCash|comma}} 원</span>
        </div>
        <div class="it">
          <span class="t1">보유머니</span><span class="t2">{{$store.state.userInfo.cash|comma}} 원</span>
        </div>
        <div class="it">
          <span class="t1 text-bet-infos">배당</span><span class="t2">{{totalOdd}}</span>
        </div>
        <div class="it">
          <span class="t1 text-bet-infos">예상당첨금액</span><span
            class="t2 text-bet-infos">{{totalWinCash|comma}}</span>
        </div>
        <div class="it">
          <span class="t1 text-bet-infos">베팅금액</span>
          <span class="t2">
                                <input type="text" v-model="betCash" @input="setBetCahsComma" style="width: 100px;"/>
                            </span>
        </div>
      </div>
      <div class="money_buttons">
        <button class="" @click="cashAdd(5000)">5천</button>
        <button class="" @click="cashAdd(10000)">1만</button>
        <button class="" @click="cashAdd(50000)">5만</button>
        <button class="" @click="cashAdd(100000)">10만</button>
        <button class="" @click="cashAdd(500000)">50만</button>
        <button class="" @click="cashAdd(1000000)">100만</button>
        <button class="" @click="maxCash()">MAX</button>
        <button class="" @click="allInCash()">올인</button>
        <button class="" @click="resetCash()">금액초기화</button>
        <!--                <button class="" @click="initBetCart()">초기화</button>-->
      </div>
      <div class="btn_bet">
        <button @click="betNow()">베팅하기</button>
        <!--                <a href="javascript:void(0)" @click="betNow()">-->
        <!--                    <img src="../../assets/images/right/cart/btn-betnow.png" style="width: 100%" alt="">-->
        <!--                </a>-->

      </div>

    </div>
  </div>

</template>
<script>
import sportsConst from "@/common/sportsConst";
import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
  RECEIVE_SPORTS_CART_ITEM_DEL,
  RECEIVE_SPORTS_CART_ITEM_DEL_ALL
} from "@/store/mutation-types";
import {dobet} from "@/network/sportsBetRequest";
import {mapGetters} from "vuex";
import {checkLoginMinxin} from "@/common/mixin";
import Clock from "@/views/afront/zero/Clock.vue";


export default {
  name: "SportsBetCart",
  mixins: [checkLoginMinxin],
  components: {Clock},
  props: {

  },
  data() {
    return {
      sportsConst,
      odds: 0,//선택경기 총배당
      winCash: 0,//총당첨금액
      betCash: 0,//베팅금액
      betInfo: {
        selectedVals: [],
        betCash: 0
      },
      bonusCash: 0,//보너스 금액
      bonusOdds: 0,//보너스 배당,
      mobileBetCartClose: false,
      locked: false,
      bonusExceptBedang: 1.4,
      crossNeedFolderCount: 2,
    }
  },
  methods: {
    /*배팅카트 아이템 삭제*/
    del(index, item) {
      item.selectWay = sportsConst.WAY_NONE
      item.selectOdd = 1;
      this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
    },
    /*베팅카드 머니 버튼클릭*/
    cashAdd(amount) {
      this.betCash = this.$root.$options.filters.comma(this.getBetCash + parseInt(amount))
    },
    setBetCahsComma(){
      if(this.betCash == ''){
        this.betCash = ''
      }else {
        this.betCash = this.$root.$options.filters.comma(this.betCash)
      }
    },
    resetCash() {
      this.betCash = '';
    },
    allInCash() {
      this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
    },
    /*베팅카드 초기화 ,아이템 전체 삭제*/
    initBetCart() {
      this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
    },
    /*상한가 베팅*/
    maxCash() {
      if (this.odds <= 1 || this.cartItems.length === 0) return false;
      if (this.displayPosition === sportsConst.GAME_DISPLAYPOSITION_SPECIAL) {
        this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.specWinMaxLimitCash - 6000) / this.odds))

        if(this.getBetCash > this.rankConfig.betMaxLimitCash){
          this.betCash = this.$root.$options.filters.comma( this.rankConfig.specBetMaxLimitCash);
        }
      } else {
        this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.winMaxLimitCash - 6000) / this.odds))
        if(this.getBetCash > this.rankConfig.betMaxLimitCash){
          this.betCash =  this.$root.$options.filters.comma(this.rankConfig.betMaxLimitCash);
        }

      }
    },
    /*베팅하기*/
    betNow() {
      if (!this.checkLogin()) {
        return false
      }
      //선택경기개수 체크
      if (this.cartItems.length === 0) {
        this.$swal({
          title: '베팅하실 경기를 선택하세요',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //축구 동일경기 크로스 베팅시
      //선택경기개수 체크
      if (!this.checkCroessBetFolderCount()) {
        this.$swal({
          title: '축구 동일경기 크로스 최소 ' + this.crossNeedFolderCount + '폴더 부터 베팅가능합니다',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }

      //단폴더 체크
      if (this.cartItems.length === 1) {
        if (this.getBetCash > this.rankConfig.betOneMaxLimitCash) {
          this.$swal({
            title: '단폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betOneMaxLimitCash) + ' 원을 초과하였습니다',
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
      }
      //두폴더 체크
      if (this.cartItems.length === 2) {
        if (this.getBetCash > this.rankConfig.betTwoMaxLimitCash) {
          this.$swal({
            title: '두폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betTwoMaxLimitCash) + ' 원을 초과하였습니다',
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
      }
      //최고베당체크
      let oddmax = this.$store.state.displayPostion === sportsConst.GAME_DISPLAYPOSITION_1x2 ? this.rankConfig.oddsMaxLimit : this.rankConfig.specOddsMaxLimit
      if (this.odds > oddmax) {
        this.$swal({
          title: '최대 배당율 ' + this.$root.$options.filters.comma(this.rankConfig.oddsMaxLimit) + ' 배를 초과하였습니다',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최소베팅금액
      if (this.getBetCash < this.rankConfig.betMinLimitCash) {
        this.$swal({
          title: '최소 베팅금액은 ' + this.$root.$options.filters.comma(this.rankConfig.betMinLimitCash) + ' 원입니다',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최대베팅금액
      let maxBetCash = this.$store.state.displayPostion === sportsConst.GAME_DISPLAYPOSITION_1x2 ? this.rankConfig.betMaxLimitCash : this.rankConfig.specBetMaxLimitCash
      if (this.getBetCash > maxBetCash) {
        this.$swal({
          title: '최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betMaxLimitCash) + ' 원을 초과하였습니다',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }
      //최대당첨금액
      let maxWinCash = this.$store.state.displayPostion === sportsConst.GAME_DISPLAYPOSITION_1x2 ? this.rankConfig.winMaxLimitCash : this.rankConfig.specWinMaxLimitCash
      if (this.totalWinCash > maxWinCash) {
        this.$swal({
          title: '최대 당첨금액 ' + this.$root.$options.filters.comma(this.rankConfig.winMaxLimitCash) + ' 원을 초과하였습니다',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false;
      }

      //베팅시작
      let betcontent = this.$root.$options.filters.comma(this.getBetCash) + '을 베팅하시겠습니까?';
      if (this.cartItems.length === 1 && this.$store.state.userInfo.rank.betOneWincashPercent > 0) {
        betcontent = '단폴더 베팅 시 -' + this.$store.state.userInfo.rank.betOneWincashPercent + ' 차감됩니다. 베팅하시겠습니까?';
      }
      this.$swal({
        title: betcontent,
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.cartItems.forEach((item) => {
            this.betInfo.selectedVals.push(item.id + '-' + item.selectWay + '-' + item.isbonus);
          })
          this.betInfo.betCash = this.getBetCash;
          this.$store.commit(RECEIVE_SHOW_LOADING)
          dobet(this.betInfo).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$swal({
                title: '베팅성공',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
              //베팅성공시 회원정보를 init
              this.$store.dispatch('actionUserInfo')
              this.betCash = 0;
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })

    },

    hideCart() {
      //모바일경우 베팅카드 숨김기능
      //RightBarComp.vue에서 모니터
      this.$bus.$emit('rightMenueHandle', false)
    },
    cartLockAble() {
      this.locked = !this.locked;
      //카트고정여부
      this.$bus.$emit('cartLockAble', this.locked)
    },
    checkCroessBetFolderCount() {
      let selectFolderCount = this.cartItems.length;
      let groupCode = '0'
      let isSoccerCross = false;
      this.cartItems.map(item => {
        if (item.isbonus === sportsConst.NOT) {
          if (item.groupCode === groupCode) {
            isSoccerCross = true
          }
          if (item.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER) {
            groupCode = item.groupCode;
          }
        }
      });

      if (!isSoccerCross) return true

      return selectFolderCount >= this.crossNeedFolderCount
    }

  },
  computed: {
    ...mapGetters({
      'cartItems': 'getSportsCartItems',
      'rankConfig': 'getRankConfig',
      'bonusConfig': 'getBonusConfig'
    }),
    /*총배당*/
    totalOdd() {
      return this.odds.toFixed(2)
    },
    /*총당첨*/
    totalWinCash() {
      if(this.betCash != ''){
        return Math.round(this.totalOdd * this.getBetCash)
      } else {
        return 0
      }
    },
    /*캐쉬가 String으로 되여 전부 Integer로 전화*/
    getBetCash() {
      if(this.betCash == '') return 0

      return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
    }
  },
  created() {
    this.betCash = ''
  },
  watch: {
    /*카드아이템이 변경될때마다 체크*/
    "cartItems": {
      deep: true,
      handler: function (newValue) {
        /*총배당을 0으로 설정*/
        this.odds = 0;
        newValue.forEach((item) => {
          if (this.odds === 0) {
            this.odds = parseFloat(item.selectOdd)
          } else {
            this.odds = this.odds * parseFloat(item.selectOdd)
          }
        })
      }
    },
    betCash() {
    },


  },
}
</script>
<style scoped>
.bet_cart {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 300;
}
.bet_cart .bet_cart_timer{
  width: 100%;
  text-align: center;
  height: 4rem;
  font-size: 1.8rem;
  font-weight: bold;
  box-sizing: border-box;
  color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bet_cart .cart_head {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .5rem;
}

.bet_cart .cart_head .title {
  width: 100%;
  --border-radius: 2px;
  border-top: 1px solid #f9a818 ;
  border-bottom: 1px solid #f9a818 ;
  background: #181818;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 1rem 0;
}

.bet_cart .cart_head .lock {
  display: inline-block;
  text-align: right;
  box-sizing: border-box;
  padding-right: .5rem;
  cursor: pointer;
  height: 3.8rem;
}

.bet_cart .cart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bet_cart .cart_selected_items {
  width: 100%;
  clear: both;
  border-radius: .5rem;
  box-sizing: border-box;
  border: 1px solid #424242;
  padding: .2rem;
  margin: .2rem auto;
  transition: 200ms all;
  --background: linear-gradient(180deg, #27272d, #27272d);
}

.bet_cart .cart_selected_items .item {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  color: #acacac;
  font-size: 1.2rem;
}

.bet_cart .info {
  width: 100%;
  border-radius: .3rem;
  background-color: var(--betInfoBg);
  border: 1px solid var(--betInfoBorderColor);
  min-height: 20rem;
  box-sizing: border-box;
  padding: .3rem;
  margin-top: .3rem;
}

.bet_cart .info .item {
  font-size: 1.2rem;
  font-weight: normal;
}

.bet_cart .info .item .it {
  --border-bottom: 1px solid var(--betCartSitemBorderColor);
  padding: .2rem 0;
}

.bet_cart .info .item span {
  display: inline-block;
  line-height: 1.4rem;
  color: #d7d7d7;
}

.bet_cart .info .item .t1 {
  width: 60%;
  text-align: left;
  box-sizing: border-box;
  padding-left: .3rem;
}

.bet_cart .info .item .t2 {
  width: 40%;
  text-align: right;
  box-sizing: border-box;
  padding-right: .3rem;
}

.bet_cart .info .item .win {
  color: #fbb80f;
  font-weight: bold;
}

.bet_cart .info .item input {
  display: inline-block;
  background-color: #272928;
  box-sizing: border-box;
  border: 1px solid #696969;
  text-align: center;
  font-size: 1.1rem;
  border-radius: 5px;
  padding: .2rem 0;
}

.bet_cart .info .money_buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: .4rem auto;
  flex-wrap: wrap;
}

.bet_cart .info .money_buttons button {
  width: 33%;
  box-sizing: border-box;
  height: 3rem;
  margin: .1rem auto;
  font-size: 1.2rem;
  background-color: var(--betcartCashAddBg);
  border-radius: 2px;
  color: #3d3d3d;
  font-weight: bold;
  border: 1px solid transparent;
}

.bet_cart .info .btn_bet {
  width: 100%;
  padding: .5rem 0;
}
.bet_cart .info .btn_bet button{
  width: 100%;
  height: 4rem;
  font-weight: bold;
  font-size: 1.8rem;
  color: #ffffff;
  background: linear-gradient(180deg, var(--betnow01), var(--betnow02));
  border-radius: 5px;
  --border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bet_cart .info .btn_bet a{
  width: 100%;
  line-height: 5rem;
  font-weight: bold;
  border-radius: .5rem;

}

.bet_cart .text-bet-infos{
  color: #ff973e!important;
}

.bet_cart .active_cart_item {
  color: #9b6c32 !important;
}

.cart .sitem {
  width: 100%;
  background-color: var(--betCartSitemBg);
  color: #5c5c5c;
  border: 1px solid var(--betCartSitemBorderColor);
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: .2rem;
  border-radius: .2rem;
}

.cart .sitem .item_name {
  width: 100%;
  height: 3.2rem;
  --border-bottom: 1px solid #4f4f51;
  display: flex;
  justify-content: space-between;
  color: #d9d9d9;
  font-weight: bold;
}

.cart .sitem .item_name .name {
  width: 50%;
  line-height: 3.2rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 .5rem;
}
.cart .sitem .item_name .selectway {
  width: 25%;
  line-height: 3.2rem;
  text-align: center;
}
.cart .sitem .item_name .hd{
  color: deepskyblue;
}
.cart .sitem .item_name .ou{
  color: orange;
}
.cart .sitem .item_name .selectodds {
  width: 15%;
  line-height: 3.2rem;
  text-align: right;
  color: #ff973e;
}

.cart .sitem .item_name .del {
  width: 10%;
  line-height: 3.2rem;
  text-align: center;
  cursor: pointer;
}

.cart .sitem .item_info {
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  padding-left: .5rem;
}

.cart .sitem .kind {
  width: 100%;
  line-height: 3.2rem;
  color: #808080;
  text-align: left;
}

.cart .sitem .sinfo {
  width: 100%;
  line-height: 3rem;
  display: flex;
  justify-content: space-between;

}

.cart .sitem .sinfo .sn {
  width: 80%;
  text-align: left;
  color: #d9d8d8;
}

.cart .sitem .sinfo .sodd {
  width: 20%;
  text-align: center;
  color: #f7c46b;
}


</style>