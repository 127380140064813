import { render, staticRenderFns } from "./EventButtonsComp.vue?vue&type=template&id=305e66fc&scoped=true"
import script from "./EventButtonsComp.vue?vue&type=script&lang=js"
export * from "./EventButtonsComp.vue?vue&type=script&lang=js"
import style0 from "./EventButtonsComp.vue?vue&type=style&index=0&id=305e66fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305e66fc",
  null
  
)

export default component.exports