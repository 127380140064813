<template>

        <div class="leisure_button_group_sub">
            <router-link tag="button" class=""  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_JWCASINO_ANGELDEMONS}"
                         :to="{path:'/front/minigame/jwcasinoangel', query: {t: new Date().getTime()}}">천사&악마</router-link>
            <router-link tag="button" class=""  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_JWCASINO_OSTRICHRUN}"
                         :to="{path:'/front/minigame/jwcasinoost', query: {t: new Date().getTime()}}">타조게임</router-link>
            <router-link tag="button" class=""  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_JWCASINO_SUPERMARIO}"
                         :to="{path:'/front/minigame/jwcasinosupermario', query: {t: new Date().getTime()}}">슈퍼마리오</router-link>
        </div>


</template>
<script>
    import leisureConst from "@/common/leisureConst";

    export default {
        name: "LeisureCompJwCasinoLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>