<template>
  <div class="content">
    <sub-title>토큰게임</sub-title>
    <bet-history-links></bet-history-links>

    <div class="minigame_bet_history">
<!--      <div class="button_group">-->
<!--        <button class="bw2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_HILOW_5S)">하이로우5초</button>-->
<!--        <button class="bw2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_HILOW)">하이로우10초</button>-->
<!--        <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_BACCARAT)">바카라</button>-->
<!--        <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_RUOLLET)">룰렛</button>-->
<!--        <button class="bw3 mt2" @click="changeType(leisureConst.LEISURE_KIND_TGAME365_FW)">포츈휠</button>-->
<!--      </div>-->
      <table class="utable">
        <tr>
          <th style="width: 15%">TID</th>
          <th style="width: 15%">타입</th>
          <th style="width: 15%">베팅금</th>
          <th style="width: 15%">당첨금</th>
          <th style="width: 15%">베팅결과</th>
          <th style="width: 15%">시간</th>
        </tr>
        <tr v-for="(item,idx) in resultList" :key="idx">
          <td>
            {{ item.transactionid }}
          </td>
          <td>{{ item.gtype }}</td>
          <td>
            {{ item.amount | comma }}
          </td>
          <td>
            {{ item.winamount | comma }}
          </td>
          <td>
            <span v-if="item.betResult === 1" class="text-skyblue">당첨</span>
            <span v-if="item.betResult === 2" class="">x</span>
          </td>
          <td>{{ item.createTime|datef('MM-DD HH:mm:ss') }}</td>

        </tr>
      </table>
      <div style="margin: 1rem .3rem;width: 100%;text-align: left">
        <button @click="deleteAll" style="padding: .5rem 2rem;background-color: red;border-radius: 3px">전체삭제</button>
      </div>
      <pagination :page-index=" pageNum"
                  :total="total"
                  :page-size="pageSize"
                  @change="pageChange"></pagination>


    </div>
  </div>


</template>

<script>
import SubTitle from "@/views/afront/zero/SubTitle";
import leisureConst from "@/common/leisureConst";
import sportsConst from "@/common/sportsConst";
import {
  deleteT365BetAll,
  deleteT365BetById,
  getThirdPartGameResult
} from "@/network/leisureRequest";
import Pagination from "@/components/pagenation/Pagination";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import BetHistoryLinks from "@/views/afront/sports/match/BetHistoryLinks.vue";


export default {
  name: "Tgame365BetInfo",
  components: {
    BetHistoryLinks,
    Pagination, SubTitle,
  },
  data() {
    return {
      leisureConst,
      sportsConst,
      pageNum: 1,
      pageSize: 20,
      total: 1,
      kind: leisureConst.LEISURE_KIND_TGAME365_HILOW,
      resultList: []
    }
  },
  methods: {
    deleteAll() {
      this.$swal({
        title: '베팅내역 전체를 삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteT365BetAll().then(res => {
            this.getThirdPartGameResult()
          })
        }
      });

    },
    deleteById(id) {
      deleteT365BetById(id).then(res => {
        this.getThirdPartGameResult()
      })
    },
    changeType(kind) {
      this.kind = kind
      this.getThirdPartGameResult()
    },
    getThirdPartGameResult() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getThirdPartGameResult(this.kind, this.pageNum, this.pageSize, this.orderBy).then(res => {
        if (res.data.success) {
          this.total = res.data.total
          this.resultList = res.data.data
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    pageChange(page) {
      this.pageNum = page
      this.getThirdPartGameResult()
    },
    leisureKindChanged(kind) {
      this.kind = kind;
      this.pageNum = 1
    }
  },
  created() {
    this.getThirdPartGameResult();
    this.$store.state.currentBetHistoryIndex = 4
  },
  watch: {
    kind() {
      this.getThirdPartGameResult();
    }
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontminigame.css");

</style>