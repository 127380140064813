<template>

  <div>
    <div class="leisure_button_group_sub">
      <router-link tag="button" class=""
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_HILOW}"
                   :to="{path:'/front/tgame365/tgame365highlow', query: {t: new Date().getTime()}}">
        하이로우 10초
      </router-link>

      <router-link tag="button" class=""
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_RUOLLET}"
                   :to="{path:'/front/tgame365/rullet', query: {t: new Date().getTime()}}">
        룰렛
      </router-link>

      <router-link tag="button" class=""
                   :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_BACCARAT}"
                   :to="{path:'/front/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">
        바카라
      </router-link>



      <!--           <router-link tag="button" class="bw3"-->
      <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_RUOLLET}"-->
      <!--                        :to="{path:'/tgame365/tgame365ruollet', query: {t: new Date().getTime()}}">-->
      <!--               룰렛-->
      <!--           </router-link>-->
      <!--           <router-link tag="button" class="bw3 mt-1"-->
      <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_TGAME365_FW}"-->
      <!--                        :to="{path:'/tgame365/tgame365fw', query: {t: new Date().getTime()}}">-->
      <!--               포츈힐-->
      <!--           </router-link>-->
      <!--           <router-link tag="button" class="bw3"-->
      <!--                        :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_COMP_TGAMEPOWERBALL}"-->
      <!--                        :to="{path:'/leisure/tgamepowerball', query: {t: new Date().getTime()}}">-->
      <!--               토큰파워볼-->
      <!--           </router-link>-->
    </div>
<!--    <div class="tgamebetcaount">-->
<!--      당일 토큰게임 베팅 {{ betcount }} 회-->
<!--    </div>-->

  </div>

</template>
<script>
import leisureConst from "@/common/leisureConst";
import {tgamebetcount} from "@/network/userRequest";

export default {
  name: "LeisureCompTgames365GameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
      betcount: 0,
    }
  },
  created() {
    // tgamebetcount().then(res => {
    //   this.betcount = res.data.data;
    // })
  }
}
</script>

<style scoped>
.tgamebetcaount {
  color: #fff9ff;
  text-align: center;
  background-color: #333333;
  margin: 5px 0;
  line-height: 28px;
}
</style>