<template>
  <div class="content">
    <sub-title>미니게임</sub-title>
    <leisure-game-comp-links></leisure-game-comp-links>

    <leisure-comp-bet365-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_BET365"></leisure-comp-bet365-game-links>
    <leisure-comp-star-sadali-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_STAR_SADALI"></leisure-comp-star-sadali-game-links>
    <leisure-comp-sky-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SKY"></leisure-comp-sky-game-links>
    <leisure-comp-tgames365-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_TOKENGAME"></leisure-comp-tgames365-game-links>

    <leisure-comp-speed-game-links  v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SPEEDGAME"></leisure-comp-speed-game-links>
    <leisure-comp-jw-casino-links  v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_JWCASINO"></leisure-comp-jw-casino-links>


    <leisure-comp-crown-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_CROWN"></leisure-comp-crown-game-links>
    <leisure-comp-e-o-s-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_EOS"></leisure-comp-e-o-s-game-links>
    <leisure-comp-h-s-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_HS"></leisure-comp-h-s-game-links>
    <leisure-comp-named-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_NAMED"></leisure-comp-named-game-links>
    <leisure-comp-next-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_NEXT"></leisure-comp-next-game-links>
    <leisure-comp-pam-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_PAMGAME"></leisure-comp-pam-game-links>
    <leisure-comp-mgm-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_MGM"></leisure-comp-mgm-game-links>
    <leisure-comp-lotus-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_LOTUS"></leisure-comp-lotus-game-links>
    <leisure-comp-sure-man-game-links v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_SUREMAN"></leisure-comp-sure-man-game-links>
    <leisure-comp-game-moa-game-links
        v-if="$store.state.currentLeisureGameComp === leisureConst.LEISURE_COMP_GAMEMOA"></leisure-comp-game-moa-game-links>
    <router-view></router-view>
    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>

</template>

<script>

    import LeisureCompGameMoaGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompGameMoaGameLinks.vue";
    import LeisureCompBet365GameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompBet365GameLinks.vue";
    import LeisureCompStarSadaliGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompStarSadaliGameLinks.vue";
    import LeisureCompEOSGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompEOSGameLinks.vue";
    import LeisureCompHSGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompHSGameLinks.vue";
    import LeisureCompTgames365GameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompTgames365GameLinks.vue";
    import LeisureCompNamedGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompNamedGameLinks.vue";
    import LeisureCompCrownGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompCrownGameLinks.vue";
    import LeisureCompSkyGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompSkyGameLinks.vue";
    import LeisureCompNextGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompNextGameLinks.vue";
    import LeisureCompPamGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompPamGameLinks.vue";
    import LeisureCompMgmGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompMgmGameLinks.vue";
    import LeisureCompLotusGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompLotusGameLinks.vue";
    import LeisureCompSureManGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompSureManGameLinks.vue";


    import leisureConst from "@/common/leisureConst";
    import {checkLoginOnCreateMinxin, maintaingMixin, postionMixin} from "@/common/mixin";
    import LeisureGameCompLinks from "@/views/afront/leisure/LeisureCompLinks.vue";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
    import LeisureCompSpeedGameLinks from "@/views/afront/leisure/linkbycompany/LeisureCompSpeedGameLinks.vue";
    import LeisureCompJwCasinoLinks from "@/views/afront/leisure/linkbycompany/LeisureCompJwCasinoLinks.vue";



    export default {
        name: "LeisureGame",
        mixins: [maintaingMixin,postionMixin,checkLoginOnCreateMinxin],
        components: {
          LeisureCompJwCasinoLinks,
          LeisureCompSpeedGameLinks,
          MyUncheckedMessageComp,
          LeisureCompSureManGameLinks,
          LeisureCompLotusGameLinks,
          LeisureCompMgmGameLinks,
          LeisureCompPamGameLinks,
          LeisureCompNextGameLinks,
          LeisureCompSkyGameLinks,
          LeisureCompCrownGameLinks,
          LeisureCompNamedGameLinks,
          LeisureCompTgames365GameLinks,
          LeisureCompHSGameLinks,
          LeisureCompEOSGameLinks,
          LeisureCompStarSadaliGameLinks,
          LeisureCompBet365GameLinks,
          LeisureCompGameMoaGameLinks,
          LeisureGameCompLinks,
          SubTitle,
        },
        data() {
            return {
                leisureConst: leisureConst,
                position: "미니게임",
            }
        },
        created() {
        }

    }
</script>

<style scoped>
@import url("../../../assets/css/front/afrontminigame.css");
</style>
