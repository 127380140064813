<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SKY_SPEED_BAKARA}"
                 :to="{path:'/front/minigame/sky_speed_bakara', query: {t: new Date().getTime()}}">SPEED바카라
    </router-link>

    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SKY_ODDEVEN}"
                 :to="{path:'/front/minigame/sky_oddeven', query: {t: new Date().getTime()}}">홀짝
    </router-link>

    <router-link tag="button" class=""
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SKY_DICE}"
                 :to="{path:'/front/minigame/sky_dice', query: {t: new Date().getTime()}}">주사위
    </router-link>

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompSkyGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>