<template>
  <div class="content">
    <sub-title>출석체크</sub-title>
    <div class="cul_main">
      <div class="box" style="margin: 0 auto">
        <div class="date">
          {{month}} 월 출석정보
          <p style="font-size: 10px;color: #c1c1c1">
            00:00~24:00 당일 입금금액 {{$store.state.userInfo.rank.culcheckrechargeamount|comma}}원이상 자동출석</p>
        </div>
        <div class="week">일</div>
        <div class="week">월</div>
        <div class="week">화</div>
        <div class="week">수</div>
        <div class="week">목</div>
        <div class="week">금</div>
        <div class="week">토</div>
        <div class="day" v-for="(item,index) in culchekinfo.daysinfo" :class="{'day2':item.day===0&&index>=35}">
          <div class="daynumber" v-if="item.day>0">{{item.day}}</div>
          <img src="../../../assets/images/afront/logo/logo.png" v-if="item.isculckeck" alt="">
        </div>
<!--        <div style="line-height: 22px;text-align: center;color: #a1a1a1;width: 100%" v-if="$store.state.userInfo.rank.loginEventUse == sportsConst.YES">-->
<!--          모든 포인트는 자동 지급됩니다-->
<!--        </div>-->
<!--        <div v-if="$store.state.userInfo.rank.loginEventUse == sportsConst.YES"  style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%">-->
<!--          &lt;!&ndash;                            <span class="record1" v-if="$store.state.userInfo.rank.loginEvent3 && $store.state.userInfo.rank.loginEvent3>0">3일 출석 시 {{$store.state.userInfo.rank.loginEvent3|comma}} 포인트</span>&ndash;&gt;-->
<!--          <span class="record2" v-if="$store.state.userInfo.rank.loginEvent7 && $store.state.userInfo.rank.loginEvent7>0">7일 출석 시 {{$store.state.userInfo.rank.loginEvent7|comma}} 포인트</span>-->
<!--          <span class="record1" v-if="$store.state.userInfo.rank.loginEvent14 && $store.state.userInfo.rank.loginEvent14>0">15일 출석 시 {{$store.state.userInfo.rank.loginEvent14|comma}} 포인트</span>-->
<!--          <span class="record2" v-if="$store.state.userInfo.rank.loginEvent21 && $store.state.userInfo.rank.loginEvent21>0">25일 출석 시 {{$store.state.userInfo.rank.loginEvent21|comma}} 포인트</span>-->
<!--                                      <span class="record1" v-if="$store.state.userInfo.rank.loginEvent28 && $store.state.userInfo.rank.loginEvent28>0">30일 출석 시 {{$store.state.userInfo.rank.loginEvent28|comma}} 포인트</span>-->
        </div>
      </div>
    </div>


    </div>

</template>


<script>

import {getCulcheck} from "@/network/userRequest";
    import {postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";

    export default {
      name: "Culcheck",
      mixins: [postionMixin],
      components: {  SubTitle },
      data() {
        return {
          sportsConst,
          culchekinfo: {},
          month: 0,
          position:"출석부",

        }
      },
      mounted() {

      },
      methods: {},
      created() {
        this.month = this.$moment().month() + 1
        getCulcheck().then(res => {
          if (res.data.success) {
            let data = res.data.data;
            let daysinfo = []
            let lastDay = this.$moment().daysInMonth();
            let startWeek = this.$moment().startOf('month').weekday()
            let daynumber = 1;
            for (let i = 0; i < 42; i++) {
              let d = {}
              this.$set(d, 'isculckeck', false)
              if (i < startWeek) {
                this.$set(d, 'day', 0)
              } else if (i >= (lastDay + startWeek)) {
                this.$set(d, 'day', 0)
              } else {
                this.$set(d, 'day', daynumber)
                daynumber++

                data.map(resdata => {
                  if (resdata.sday == d.day) this.$set(d, 'isculckeck', true)
                })
              }


              daysinfo.push(d)
            }
            this.$set(this.culchekinfo, 'startWeek', this.$moment().startOf('month').weekday())
            this.$set(this.culchekinfo, 'daysinfo',daysinfo)
          }
        })
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontculcheck.css");


</style>