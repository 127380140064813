<template>
  <div class="menu_title" style="">
    <slot></slot>
  </div>

</template>
<script>
export default {
  name: "LeftMenuTitle"
}
</script>
<style scoped>
.menu_title {
  width: 100%;
  padding-left: 1rem;
  color: #fff9ff;
  height: 4rem;
  background-color: #141414;
  border-bottom: 1px solid #dcc42f;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}
</style>