import { render, staticRenderFns } from "./Exchange.vue?vue&type=template&id=6f74eaba&scoped=true"
import script from "./Exchange.vue?vue&type=script&lang=js"
export * from "./Exchange.vue?vue&type=script&lang=js"
import style0 from "./Exchange.vue?vue&type=style&index=0&id=6f74eaba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f74eaba",
  null
  
)

export default component.exports