<template>
  <div class="content">
    <sub-title>카지노</sub-title>
    <div class="casinoslot_panel">
      <money-chip-change-comp></money-chip-change-comp>
      <div class="items">
        <div class="itm">
          <div class="pic" @click="openGame('PowerBall0000001','evolution',3)">
            <img src="../../../assets/images/casino/c/00.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('evolution_baccarat_sicbo','evolution',3)">
            <img src="../../../assets/images/casino/c/01.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('dgcasino','DreamGame',3)">
            <img src="../../../assets/images/casino/c/02.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('B001','Asia Gaming',3)">
            <img src="../../../assets/images/casino/c/03.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('401','PragmaticPlay Live',3)">
            <img src="../../../assets/images/casino/c/04.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('bota','bota',3)">
            <img src="../../../assets/images/casino/c/05.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('wmcasino','WM Live',3)">
            <img src="../../../assets/images/casino/c/06.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('MX-LIVE-998','sexybcrt',3)">
            <img src="../../../assets/images/casino/c/07.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('SMG_titaniumLiveGames_MP_Baccarat','MicroGaming Plus',3)">
            <img src="../../../assets/images/casino/c/08.jpg" alt="">
          </div>
        </div>
        <div class="itm">
          <div class="pic" @click="openGame('sw_ro_spbac','Skywind Live',3)">
            <img src="../../../assets/images/casino/c/09.jpg" alt="">
          </div>
        </div>

      </div>
    </div>

    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>

</template>


<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {casinoMixin, checkLoginOnCreateMinxin, maintaingMixin, postionMixin} from "@/common/mixin";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import MoneyChipChangeComp from "@/views/afront/casino/MoneyChipChangeComp.vue";
    import {getCasinoMaintaining} from "@/network/casinoHonorRequest";
    import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
    export default {
      name: "CasinoHonor",
      mixins: [maintaingMixin,postionMixin,casinoMixin,checkLoginOnCreateMinxin],
      components: {
        MyUncheckedMessageComp,
        MoneyChipChangeComp,
        SubTitle

      },
      data() {
        return {
          position: "카지노",
        }
      },
      methods: {

      },
      created() {
        if (this.$store.state.isLogin) {
          getCasinoMaintaining().then(res => {
            if (res.data.success) {
              if (!res.data.data) {
                this.$swal({
                  title: '카지노 점검중입니다',
                  type: 'error',
                  confirmButtonText: '확인',
                  showCancelButton: false,
                  showConfirmButton: true
                })
              }
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }

          })
        }
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");
</style>