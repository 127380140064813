<template>
  <div class="popup">

    <div class="popup_section" v-for="(item,index) in plist"
         :key="index" style=""
         :style="{zIndex: 10000-index}"
         v-show="item.isShow && $store.state.popups.indexOf(item.id) === -1">

      <div class="pop_box">
        <div class="event_img">
          <img v-if="item.type === 2" :src="item.imageUrl" alt="" style="width: 100%;">
          <span v-else v-html="item.content"> </span>
        </div>
      </div>
      <div class="pfoot">
        <div @click="close24(item)">
          <i class="fa fa-square"></i> 오늘하루 그만보기
        </div>
        <div>
          <button class="btn-close" @click="close(item)">닫기</button>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import {getPopupList} from "@/network/userRequest";
import sportsConst from "@/common/sportsConst";

export default {
  name: "PopupComp",
  data() {
    return {
      plist: []
    }
  },
  methods: {
    close24(item) {
      this.$set(item, 'isShow', false)
      this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
    },
    close(item) {
      //this.$store.state.popups.push(item.id)
      this.$set(item, 'isShow', false)
    },
    getPopList() {
      getPopupList(sportsConst.POPUP_PLATFORM_MAIN).then(res => {
        if (res.data.success) {
          this.plist = res.data.data
          this.plist.forEach(popup => {
            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
            this.$set(popup, 'isShow', cookiePopupStatus == null)

          })
        }
      })
    }
  },
  created() {
    if (this.$route.path.indexOf('/main') != -1) {
      this.getPopList()
    }

  }
}
</script>

<style scoped>

.popup {
  position: absolute;
  z-index: 10011;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  top: 15rem;


}

.popup_section {
  background-color: #1e1e1e;
  color: #fff9ff;
  margin: .5rem;
  width: 37.5rem;
}

.popup_section .btn-close {
  padding: .2rem;
  background-color: #676665;
  color: #e1e1e1;
  border: 0;
  border-radius: .5rem;
  font-size: 1.4rem;
}

.pop_box {
  box-sizing: border-box;
}

.pop_box h2 span:hover {
  color: #f19f09;
}

.popup_section .pfoot {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0 .5rem;
  height: 3rem;
}

.popup_section .pfoot div, button {
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  .popup {
    width: 98% !important;
    top: 0;
  }

  .popup_section {
    position: absolute !important;
    width: 100% !important;
  }
}
</style>