<template>

  <div class="rightwarp" :class="{'activeRightMenu':this.activeRightMenu}">
    <div class="content">
      <right-user-login-comp v-if="$store.state.isLogin == false && $store.state.isMaintaining == false"></right-user-login-comp>

      <right-user-info-comp v-if="$store.state.isLogin == true && $store.state.isMaintaining == false"></right-user-info-comp>
      <event-buttons-comp v-if="$store.state.isLogin == true && $store.state.isMaintaining == false"></event-buttons-comp>

      <!--베팅카드-->
      <sports-bet-cart v-if="$store.state.isinplay === false"></sports-bet-cart>
      <inplay-bet-cart v-if="$store.state.isinplay === true"></inplay-bet-cart>

      <!--배너-->
      <right-banner-comp></right-banner-comp>
    </div>

  </div>

</template>

<script>
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import RightUserLoginComp from "@/views/afront/user/RightUserLoginComp.vue";
import RightBannerComp from "@/views/afront/zero/RightBannerComp.vue";
import RightUserInfoComp from "@/views/afront/user/RightUserInfoComp.vue";
import SportsBetCart from "@/views/afront/sports/match/SportsBetCart.vue";
import InplayBetCart from "@/views/afront/sports/match/InplayBetCart.vue";
import EventButtonsComp from "@/views/afront/zero/EventButtonsComp.vue";

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "Right",
  components: {EventButtonsComp, InplayBetCart, SportsBetCart, RightUserInfoComp, RightBannerComp, RightUserLoginComp},
  data() {
    return {
      activeRightMenu: false,
    }
  },
  methods: {

  },
  created() {
    this.$bus.$on('activeRightMenu', (status) => {
      this.activeRightMenu = status
    })

  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.activeRightMenu {
  transform: translateX(0%) !important;
  transition: 0.3s transform;
}
</style>