

<template>
  <div class="left_menu_casino_slot_img">
    <router-link tag="div" :to="{path:'/front/casino'}" @click.native="mobileMenuAction"><img src="../../../assets/images/left/casinoslot/01.jpg"></router-link>
    <router-link tag="div" :to="{path:'/front/slot'}" @click.native="mobileMenuAction"><img src="../../../assets/images/left/casinoslot/02.jpg"></router-link>
  </div>
</template>
<script>
export default {
  name: "LeftMenuLinkCasinoSlotComp",
  methods:{
    mobileMenuAction(){
      this.$bus.$emit('activeLeftMenu', false);
    }
  }
}
</script>
<style scoped>
.left_menu_casino_slot_img{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.left_menu_casino_slot_img div{
  width: 49.5%;
  cursor: pointer;
}
.left_menu_casino_slot_img img{
  width: 100%;
}

</style>