<template>
  <div class="header">
    <div class="h1">
      <div class="headerleftmenu">
        <i class="iconfont icon-menu" @click="activeLeftMenu"></i>
      </div>
      <router-link tag="div" to="/front/main" class="logo">
        <img src="../../assets/images/afront/logo/logo.png" alt="">
      </router-link>
      <div class="topbar-line-notice">
        <i class="iconfont icon-gonggao"></i>
        <div class="mwarp">
          <vue-marquee color="#ffffff" :key="1"  :speed="4">
            <slot>{{ lineMessage }}</slot>
          </vue-marquee>
        </div>
      </div>
      <div class="topbar-right-menu">
        <router-link tag="div" to="/front/recharge" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/입금.png" alt="">충전
        </router-link>
        <router-link tag="div" to="/front/exchange" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/출금.png" alt="">환전
        </router-link>
        <router-link tag="div" to="/front/customercenter" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/문의.png" alt="">문의
        </router-link>
        <router-link tag="div" to="/front/event" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/이벤트.png" alt="">이벤트
        </router-link>

        <router-link tag="div" to="/front/notice" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/공지.png" alt="">공지
        </router-link>
        <router-link tag="div" to="/front/culcheck" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/출석.png" alt="">출석부
        </router-link>
        <router-link tag="div" to="/front/coupon" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/쿠폰.png" alt="">쿠폰
        </router-link>
        <router-link tag="div" to="/front/message" class="top-small-btn">
          <img src="../../assets/images/icon/topbar/buttons/쪽지.png" alt="">쪽지
        </router-link>
<!--        <router-link tag="div" to="/front/refusers" class="top-small-btn"><i-->
<!--            class="iconfont icon-tuijianren btn-icon-small"></i>추천인-->
<!--        </router-link>-->
        <div class="top-small-btn logout" v-if="$store.state.isLogin == true" @click="logout">
          <img src="../../assets/images/icon/topbar/buttons/로그아웃.png" alt="">로그아웃
        </div>
        <div class="top-small-btn logout" v-if="$store.state.isLogin == false" @click="showLoginPanel"><i
            class="iconfont icon-login btn-icon-small"></i>로그인
        </div>
        <div class="top-small-btn logout" v-if="$store.state.isLogin == false" @click="showRegisterPanel"><i
            class="iconfont icon-login btn-icon-small"></i>회원가입
        </div>
      </div>
      <div class="headerrightmenu">
        <i class="iconfont icon-cart" @click="activeRightMenu"></i>
      </div>
    </div>
    <div class="h2">
      <router-link to="/front/sports" tag="div" class="menu">스포츠</router-link>
      <router-link to="/front/sports_special" tag="div" class="menu">스페셜</router-link>
      <router-link to="/front/inplay" tag="div" class="menu">인플레이</router-link>
      <router-link to="/front/casino" tag="div" class="menu">카지노</router-link>
      <router-link to="/front/slot" tag="div" class="menu">슬롯</router-link>
      <router-link to="/front/minigame/ssd1" tag="div" class="menu">미니게임</router-link>
      <router-link to="/front/tgame/hilow10s" tag="div" class="menu">토큰게임</router-link>
      <router-link to="/front/minigame/sky_speed_bakara" tag="div" class="menu">스카이파크</router-link>
      <router-link to="/front/minigame/bet365_superleague" tag="div" class="menu">가상스포츠</router-link>
<!--      <router-link to="/front/notice" tag="div" class="menu">공지사항</router-link>-->
<!--      <router-link to="/front/event" tag="div" class="menu">이벤트</router-link>-->
      <router-link to="/front/sportsbethistory" tag="div" class="menu">베팅내역</router-link>
      <router-link to="/front/customercenter" tag="div" class="menu">고객센터</router-link>

<!--      <router-link to="/front/sportsresult" tag="div" class="menu">경기결과</router-link>-->
<!--      <router-link to="/front/freeboard" tag="div" class="menu">게시판</router-link>-->
    </div>

    <div class="mobile_mwarp">
      <i class="iconfont icon-gonggao" style="position: absolute;left: .5rem;"></i>
      <vue-marquee color="#ffffff" :key="2" :speed="4">
        <slot>{{ lineMessage }}</slot>
      </vue-marquee>
    </div>

    <div class="mobilemask" :class="{'activemobilemask':mobilemask}" @click="hideleftrightitems"></div>


    <popup-comp></popup-comp>

  </div>
</template>

<script>
import '@/assets/css/front/afrontcommon.css'
import '@/assets/css/front/afrontindex.css'
import '@/assets/css/front/afrontmedia.css'
import '@/assets/font/iconfont/iconfont.css'
import VueMarquee from 'aring-vue-marquee'
import {INIT_USERINFO} from "@/store/mutation-types";
import {getLineMessage, getSiteStatus, logout} from "@/network/userRequest";
import RightUserLoginComp from "@/views/afront/user/RightUserLoginComp.vue";
import {getAccountBalance} from "@/network/casinoHonorRequest";
import PopupComp from "@/views/afront/notice/PopupComp.vue";

export default {
  name: "Header",
  components: {PopupComp, RightUserLoginComp, VueMarquee},
  data() {
    return {
      mobilemask: false,
      lineMessage: '',
    }
  },
  methods: {
    showLoginPanel() {
      if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
        this.$store.state.isShowLoginPanel = true
        this.$store.state.isShowRegisterPanel = false
      }
    },
    showRegisterPanel() {
      this.$store.state.isShowRegisterPanel = true
      this.$store.state.isShowLoginPanel = false
    },
    logout() {
      this.$swal({
        title: '로그아웃 하시겠습니까 ?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          this.$store.commit(INIT_USERINFO);
          window.sessionStorage.clear();
          this.$store.state.isLogin = false;
          this.$store.state.uncheckedMessageCount = 0;
          this.$router.replace("/memberlogin")
          //this.$router.replace("/front/main")
          logout();
        }
      })

    },
    activeLeftMenu() {
      this.mobilemask = true;
      this.$bus.$emit('activeLeftMenu', true);
    },
    activeRightMenu() {
      this.mobilemask = true;
      this.$bus.$emit('activeRightMenu', true);
    },
    hideleftrightitems() {
      this.mobilemask = false;
      this.$bus.$emit('activeLeftMenu', false);
      this.$bus.$emit('activeRightMenu', false);
    },
    browser() {
      let u = navigator.userAgent
      let app = navigator.appVersion
      return {         //移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE
        presto: u.indexOf('Presto') > -1, //opera
        webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
        iPhone: u.indexOf('iPhone') > -1, //
        iPad: u.indexOf('iPad') > -1, //
        webApp: u.indexOf('Safari') == -1//
      }
    },
    alertTgame() {
      alert('토큰게임은 유료게임 입니다. 고객센터 텔로 문의주세요');
    }
  },
  created() {
    this.$bus.$on('activeLeftMenu', (status) => {
      this.mobilemask = status
    })
    this.$bus.$on('activeRightMenu', (status) => {
      this.mobilemask = status
    })

    if (this.$store.state.isLogin == false) {
      if (this.$route.path.indexOf('main') == -1) {
        //this.$store.state.isShowLoginPanel = true;
        this.$router.replace("/memberlogin")
        //this.$router.replace("/front/main")
      }
    }
    //한줄공지
    getLineMessage().then(res => {
      if (res.data.success) {
        let lines = res.data.data;
        lines.map(line => {
          this.lineMessage = this.lineMessage + '   ' + line.content
        })
      }
    })
    //사이트설정
    this.$store.dispatch('actionCommonConfig')

    if (this.$store.state.isLogin) {
      this.$store.state.uncheckedMessageCount = 0;
      //회원정보
      this.$store.dispatch('actionUserInfo')
      //미확인메세지
      this.$store.dispatch('actionUnCheckedMessageCount')
      //카지노머니
      setTimeout(() => {
        getAccountBalance().then(res => {
          this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
        })
      }, 500)
    } else {
      this.$store.commit(INIT_USERINFO);
    }
  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontheader.css");
.header{
  position: relative;
}
</style>