

<template>
  <div class="left_menu_cus">
    <router-link to="/front/recharge" tag="span" class="m1bg" @click.native="mobileMenuAction"><i class="iconfont icon-chongzhi btn-icon-normal"></i>충전</router-link>
    <router-link to="/front/exchange" tag="span" class="m1bg lrb_border" @click.native="mobileMenuAction"><i class="iconfont icon-tikuan btn-icon-normal"></i>환전</router-link>
    <router-link  to="/front/customercenter" tag="span" class="m1bg" @click.native="mobileMenuAction"><i class="iconfont icon-kefu btn-icon-small"></i>문의</router-link>

    <router-link to="/front/sports" tag="span" @click.native="mobileMenuAction">스포츠</router-link>
    <router-link to="/front/sports_special" tag="span" @click.native="mobileMenuAction" class="lrb_border">스페셜</router-link>
    <router-link to="/front/inplay" @click.native="mobileMenuAction" tag="span">인플레이</router-link>

    <router-link to="/front/minigame/ssd1" @click.native="mobileMenuAction" tag="span">미니게임</router-link>
    <router-link to="/front/tgame/hilow10s" @click.native="mobileMenuAction" tag="span" class="lrb_border">토큰게임</router-link>
    <router-link to="/front/minigame/sky_speed_bakara" @click.native="mobileMenuAction" tag="span" >스카이파크</router-link>

    <router-link to="/front/minigame/bet365_superleague" @click.native="mobileMenuAction" tag="span">가상스포츠</router-link>
    <router-link to="/front/sportsbethistory" @click.native="mobileMenuAction" tag="span" class="lrb_border">베팅내역</router-link>
    <router-link to="/front/culcheck" @click.native="mobileMenuAction" tag="span" >출석부</router-link>

    <router-link to="/front/coupon" @click.native="mobileMenuAction" tag="span">쿠폰</router-link>
    <router-link to="/front/event" @click.native="mobileMenuAction" tag="span"  class="lrb_border">이벤트</router-link>
    <router-link to="/front/notice" @click.native="mobileMenuAction" tag="span">공지사항</router-link>
  </div>
</template>
<script>
export default {
  name: "LeftMenuLinkComp",
  methods:{
    mobileMenuAction(){
      this.$bus.$emit('activeLeftMenu', false);
    }
  }
}
</script>
<style scoped>
.iconfont {
  color: #ffffff;
}
</style>