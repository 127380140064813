<template>
  <div class="footer">
    <div class="pcf">
      <div class="item"><img src="../../assets/images/afront/logo/thirdpartylogo/evolution.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/dreamgame.png" alt=""></div>
      <div class="item"> <img src="../../assets/images/logo/thirdpartylogo/asiagame.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/pragmatic.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/microgaming.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/boongo.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/habanero.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/cq9.png" alt="" style="height: 4rem"></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/sexygame.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/playson.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/game_art.png" alt=""></div>
      <div class="item"><img src="../../assets/images/logo/thirdpartylogo/relatime.png" alt=""></div>
  <div class="item"><img src="../../assets/images/logo/thirdpartylogo/nolimitcity.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/skywind.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/wmgcasino.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/bet365.png" alt="" style="height: 3rem"></div>
      <!--        <div class="item"><img src="../../assets/images/logo/thirdpartylogo/caleta.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/dowin.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/evoplay.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/ezugi.png" alt=""></div>
    <div class="item"><img src="../../assets/images/logo/thirdpartylogo/fcfachai.png" alt=""></div>-->


    </div>
    <div class="mobilef">
      <router-link tag="div" to="/front/recharge" class="item">
        <i class="iconfont icon-chongzhi"></i>
        <span>입금</span>
      </router-link>
      <router-link tag="div" to="/front/exchange" class="item">
        <i class="iconfont icon-tikuan"></i>
        <span>출금</span>
      </router-link>
      <router-link tag="div" to="/front/main" class="item">
        <i class="iconfont icon-shouye"></i>
        <span>홈</span>
      </router-link>
      <router-link tag="div" to="/front/customercenter" class="item">
        <i class="iconfont icon-kefu"></i>
        <span>문의</span>
      </router-link>
      <router-link tag="div" to="/front/message" class="item">
        <i class="iconfont icon-message"></i>
        <span>쪽지</span>
      </router-link>
    </div>
  </div>
</template>

<script>

import PopupComp from "@/views/afront/notice/PopupComp.vue";

export default {
  name: "Footer",
  components: {PopupComp},
  data() {
    return {}
  },

  methods: {},
  created() {

  },
  watch: {}
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontfoot.css");


</style>