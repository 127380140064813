<template>
  <div style="width: 100%">
    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                   :to="{path:'/front/minigame/ssd1', query: {t: new Date().getTime()}}">
       보스코어
      </router-link>
      <router-link tag="button" class="bw4 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                   :to="{path:'/front/minigame/bet365_superleague', query: {t: new Date().getTime()}}">
       BET365
      </router-link>
      <router-link tag="button" class="bw4 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"
                   :to="{path:'/front/minigame/sky_speed_bakara', query: {t: new Date().getTime()}}">
        스카이파크
      </router-link>
      <router-link tag="button" class="bw4 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TOKENGAME}"
                   to="/front/tgame/hilow10s">
        토큰게임
      </router-link>

    </div>
    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SPEEDGAME}"
                   :to="{path:'/front/minigame/speedgamepdali', query: {t: new Date().getTime()}}">
        게임스타
      </router-link>

      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_JWCASINO}"
                   :to="{path:'/front/minigame/jwcasinoangel', query: {t: new Date().getTime()}}">
      JW게임
      </router-link>
      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CANDY}"
                   :to="{path:'/front/minigame/candy_dragontiger', query: {t: new Date().getTime()}}">
        용호게임
      </router-link>
      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_ROYAL}"
                   :to="{path:'/front/minigame/royalsutda', query: {t: new Date().getTime()}}">
        로얄섯다
      </router-link>


    </div>

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";


export default {
  name: "LeisureGameCompLinks",
  data() {
    return {
      leisureConst: leisureConst,

    }
  },
  methods: {
    alertTgame(){
      alert('토큰게임은 유료게임 입니다. 고객센터 텔로 문의주세요');
    },
    commingsoon() {
      alert('준비중입니다')
    },
  },
  created() {

  }
}
</script>

<style scoped>
.leisure_buttons_group img {
  width: 25px;
}
</style>