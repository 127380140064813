<template>
  <div class="content">
    <sub-title>이벤트</sub-title>
    <div class="notice">
      <div class="notice_list">

        <router-link :to="{path:'/front/event_detail/' + item.id + '?t='+new Date().getTime()}" tag="div" class="eventitem"
                     :style="{'background':`url('${item.eventImg}')`}"
                     v-for="item in eventList">
        </router-link>
      </div>
    </div>
  </div>

</template>


<script>

import {
  getEvent,
} from "@/network/userRequest";
import {checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "Event",
  mixins: [postionMixin, checkLoginOnCreateMinxin],
  components: {SubTitle},
  data() {
    return {
      eventList: [],
      pageNum: 1,
      pageSize: 100,
      total: 1,
      sportsConst,
      clickNumber: 0,
      position: "이벤트",
    }
  },
  methods: {

    initEvent() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getEvent(this.pageNum, this.pageSize).then(res => {
        if (res.data.success) {
          this.eventList = res.data.data
          this.total = res.data.total
          this.eventList.map(item => {

            if (item.id == this.clickNumber) {
              this.$set(item, 'contentShow', true)
            } else {
              this.$set(item, 'contentShow', false)
            }
          })
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    showContent(id) {
      if (id === this.clickNumber) {
        this.clickNumber = 0
      } else {
        this.clickNumber = id;
      }
      this.eventList.map(item => {
        if (item.id == id) {
          this.$set(item, 'contentShow', !item.contentShow)
        }

      })
    },
    pageChange(page) {
      this.pageNum = page
      this.initEvent()
    },
  },
  created() {
    this.clickNumber = this.$route.query.id
    this.initEvent()
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontnotice.css");
.notice_list{
  border: transparent;
}

</style>