<template>
  <div class="content">
    <sub-title>고객센터</sub-title>

      <div class="main_panel">
        <div class="qa_item" style="border: transparent">
          <div class="qa">
            <div class="qt">
              <i class="fa fa-pencil" aria-hidden="true"></i> 글쓰기
            </div>
            <span v-if="sbidcontent!= null" style="color: #a3a3a3;">*{{sbidcontent}}</span>
            <span v-if="lbidcontent!= null" style="color: #a3a3a3;">*{{lbidcontent}}</span>
            <textarea v-model="content" rows="10"></textarea>
            <div class="btn-area">
              <button class="ask" @click="questioAcNo">계좌문의
              </button>
              <button class="wr" @click="saveQuestion()">등록
              </button>

            </div>
          </div>
        </div>
      </div>
      <div class="main_panel">
        <div class="qa_item" v-for="(item, index) in questionList" :key="index">
          <div class="qa">
            <div class="qt">
              <span>{{item.createTime|datef('MM/DD HH:mm')}} </span>
              <button v-if="item.status == sportsConst.STATUS_REPLYED" @click="del(item.id)"
                      class="del">삭제
              </button>
            </div>
            <div class="q" v-html="item.content" @click="showReplay(item.id)">
            </div>
            <div class="as">
              <div class="qt">
                  <span v-if="item.contentReply" @click="showReplay(item.id)" style="cursor: pointer">
                    <span><i class="fa fa-check" aria-hidden="true" style="color: #fff9ff;font-size: 16px"></i> {{item.updateTime|datef('MM/DD HH:mm')}}</span>
                  </span>
                <span v-else @click="showReplay(item.id)" style="color: #fff9ff">
                    <i class="fa fa-share" aria-hidden="true"></i> <span>답변대기중</span>
                  </span>
              </div>
              <div class="q" v-if="item.contentReply" v-html="item.contentReply">

              </div>

            </div>

          </div>
        </div>
      </div>

      <!--페이지-->
      <pagination :page-index="pageNum"
                  :total="total"
                  :page-size="pageSize"
                  @change="pageChange"
                  v-if="questionList.length > 0"></pagination>

    </div>

</template>


<script>

    import {delQuestion, getQuestionList, saveQuestion} from "@/network/userRequest";
    import Pagination from "@/components/pagenation/Pagination";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "@/store/mutation-types";
    import {maintaingMixin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import {dobet} from "@/network/sportsBetRequest";

    export default {
      name: "CustomerCenter",
      mixins: [maintaingMixin,postionMixin],
      components: {
        Pagination, SubTitle,

      },
      data() {
        return {
          pageNum: 1,
          pageSize: 3,
          total: 1,
          orderBy: null,
          search: {},
          questionList: [],
          content: null,
          sportsConst,
          currentId: 0,
          position: "고객센터",
          type: sportsConst.CUSTOMER_TYPE_NORMAL,
          sbidcontent: null,
          lbidcontent: null,
          customerBody: {}
        }
      },
      methods: {
        del(id) {
          delQuestion(id).then(res => {
            if (res.data.success) {
              this.initQuestion()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        },
        showReplay(id) {
          if (this.currentId === id) {
            this.currentId = 0
          } else {
            this.currentId = id
            this.initQuestion()
          }
        },
        saveQuestion() {
          // let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).{1,}$/
          // if (!(regx.test(this.content))) {
          //   //if(false){
          //   this.$swal({
          //     title: '한글이 포함된 내용으로 작성해주세요',
          //     type: 'error',
          //     showCancelButton: false,
          //     showConfirmButton: true
          //   })
          //   return false;
          // }
          this.$store.commit(RECEIVE_SHOW_LOADING)
          this.customerBody.content = this.content
          this.customerBody.type = this.type

          saveQuestion(this.customerBody).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            this.type = sportsConst.CUSTOMER_TYPE_NORMAL
            if (res.data.success) {
              this.content = ''
              this.$swal({
                title: '문의글 작성이 완료되였습니다',
                type: 'success',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })

              this.initQuestion()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        },
        questioAcNo() {
          if (this.sbidcontent != null || this.lbidcontent != null) {
            this.$swal({
              title: '베팅내역이 첨부된 문의는 수동으로 작성해주세요',
              type: 'error',
              confirmButtonText: '확인',
              showCancelButton: false,
              showConfirmButton: true
            })
            return false;
          }

          this.$swal({
            title: '계좌번호 요청메세지를 보내시겠습니까?',
            type: 'question',
            confirmButtonText: ' 예 ',
            cancelButtonText: '아니오'
          }).then((res) => {
            if (res.value) {
              this.content = '계좌번호 문의합니다'
              this.type = sportsConst.CUSTOMER_TYPE_BANK_CARD
              this.saveQuestion()
            }
          })

        },
        initQuestion() {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          getQuestionList(this.pageNum, this.pageSize, this.orderBy).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.total = res.data.total
              this.questionList = res.data.data
            }
          })
        },
        //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
        pageChange(page) {
          this.pageNum = page
          this.initQuestion()
        },
      },
      created() {
        // if (this.$route.query.sbid) {
        //   this.customerBody.sbidstr = this.$route.query.sbid
        //   this.sbidcontent = '스포츠 베팅내역이 첨부되였습니다';
        // }
        // if (this.$route.query.lbid) {
        //   this.customerBody.lbidstr = this.$route.query.lbid
        //   this.lbidcontent = '미니게임 베팅내역이 첨부되였습니다';
        // }
        this.initQuestion()
      }
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcustomercenter.css");




</style>